import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import {
  findWeightedLineByLeastSquares,
  calculateBetaCoefficient,
} from "../functions/functions";
import { useSelector } from "react-redux";

export const ScatterDoubleContest = ({
  dataset,
  bisector,
  getLabels,
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {
  const containerRef = useRef();

  const labels = useSelector(getLabels);
  // toggle between showing the -100 to 100 range or extending for the data range
  let show_full_axis_range = true;

  useEffect(() => {
    if (dataset === undefined) return;

    const domain = [-1, 1];
    const { pred_values, result_values, m, b } = findWeightedLineByLeastSquares(
      dataset.map((d) => d.performance_importance),
      dataset.map((d) => d.performance_satisfaction),
      dataset.map((d) => d.rater_pct),
      domain
    );

    const plot = Plot.plot({
      marginBottom: 60,
      marginLeft: 80,
      x: {
        domain: show_full_axis_range
          ? [-1, 1]
          : d3.extent(dataset.map((d) => d.performance_importance)),
        tickFormat: d3.format(".0%"),
      },
      y: {
        domain: show_full_axis_range
          ? [-1, 1]
          : d3.extent(dataset.map((d) => d.performance_satisfaction)),
        tickFormat: d3.format(".0%"),
      },
      r: { domain: [0, 100], percent: true, range: [3, 18] },
      aspectRatio: show_full_axis_range ? 1 : null,

      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "16px",
          tickSpacing: 100,
          label: xtitle,
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "16px",
          tickSpacing: 100,
          label: ytitle,
        }),
        // axes lines
        Plot.ruleX([0]),
        Plot.ruleY([0]),
        //bisector line
        bisector &&
          Plot.line(
            [
              [-1, -1],
              [1, 1],
            ],
            {
              strokeWidth: 0.5,
              stroke: "black",
              strokeDasharray: "7,10", // Creates a dotted line (2 units dash, 2 units gap)
            }
          ),

        // linear regression line

        Plot.line(pred_values, {
          tip: true,
          strokeWidth: 1.5,
          stroke: "blue",
          title: (d) => `Beta Coeficient: ${m.toFixed(2)}`,
        }),
        // main scatterplot, with points sized by rater_pct
        Plot.dot(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          r: "rater_pct",
          fill: (d) => (labels.includes(d.scelto) ? "lightgreen" : "lightblue"),
          stroke: (d) => (labels.includes(d.scelto) ? "darkblue" : "white"),
          tip: true,
          title: (d) =>
            `${d.scelto}\n\nx: ${d3.format(".1%")(
              d.performance_importance
            )}\ny: ${d3.format(".1%")(
              d.performance_satisfaction
            )}\nscelto da: ${d3.format(".0%")(d.rater_pct)}`,
        }),
        // text on top of each point, representing the rater_pct
        Plot.text(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          text: (d) => "",
        }),
        // explicit labels only on items rated by more than min_pct_value
        Plot.text(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          text: "scelto",
          textAnchor: "start",
          fontSize: "14px",
          dx: 20,
          dy: 0,
          filter: (d) => labels.includes(d.scelto),
        }),
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset, labels]);

  return <div ref={containerRef} />;
};
